import React, { useEffect, useState } from "react";
import facebook from "../../assets/images/facebook.png";
import instagram from "../../assets/images/instagram.png";
import linkdin from "../../assets/images/linkdin.png";
import twitter from "../../assets/images/twitter.png";

import { Link } from "react-router-dom";

import { useLocation } from "react-router-dom";
import DownloadOurApp from "./DownloadOurApp";
import PartnerHospitals from "./PartnerHospitals";
function Footer() {
  const location = useLocation();
  //  useEffect(() => {
  //     const url=window.location.href;
  //     // console.log("urlasxcasdzxcasdzx", url)
  //   }, []);

  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 200) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  
  return (
    <React.Fragment>
      <section className="mhc-mobile-menu d-block d-lg-none">
        <div className="dd">
          <ul className="list-inline d-flex justify-content-between mb-0">
            <li
              className={`list-inline-item ${
                location.pathname === "/book-consult"
                  ? "active"
                  : location.pathname === "/book-consult/consult-doctor"
                  ? "active"
                  : location.pathname === "/doctor-profile"
                  ? "active"
                  : location.pathname === "/Booking-confirmed"
                  ? "active"
                  : ""
              }`}
            >
              <Link
                className="d-flex align-items-center flex-column justify-content-center"
                to="/book-consult"
              >
                <div className="image-holder">
                  <i className="mhc-icon icon-book-consult"></i>
                </div>
                <span>
                  Book <br /> Consult
                </span>
              </Link>
            </li>
            <li
              className={`list-inline-item ${
                location.pathname === "/book-package-or-test"
                  ? "active"
                  : location.pathname === "/package-comparison"
                  ? "active"
                  : location.pathname === "/package-booking"
                  ? "active"
                  : location.pathname === "/package-booking-confirm"
                  ? "active"
                  : ""
              }`}
            >
              <Link
                className="d-flex align-items-center flex-column justify-content-center"
                to="/book-package-or-test"
              >
                <div className="image-holder">
                  <i className="mhc-icon icon-book-tests"></i>
                </div>
                <span>
                  Book <br /> Tests
                </span>
              </Link>
            </li>
            <li
              className={`list-inline-item ${
                location.pathname === "/order-medicine" ? "active" : ""
              }`}
            >
              <Link
                className="d-flex align-items-center flex-column justify-content-center"
                to="/order-medicine"
              >
                <div className="image-holder">
                  <i className="mhc-icon icon-order-medicines"></i>
                </div>
                <span>
                  Order <br /> Medicines
                </span>
              </Link>
            </li>
            {/* <li className={`list-inline-item ${(location.pathname==="/homecare-services") ? "active" :(location.pathname==="/homecare-service-category") ? "active" :(location.pathname==="/homecare-service-category-compare") ? "active" :(location.pathname==="/home-care-login") ? "active" :(location.pathname==="/care-booking-confirmed") ? "active"  :""}`} > */}
            <li className="list-inline-item">
              <Link
                className="d-flex align-items-center flex-column justify-content-center"
                to="/homecare-services"
              >
                <div className="image-holder">
                  <i className="mhc-icon icon-homecare-services"></i>
                </div>
                <span>
                  Homecare <br /> Services
                </span>
              </Link>
            </li>
            {/* <li className={`list-inline-item ${(location.pathname==="/care-package-categories") ? "active" :(location.pathname==="/care-package-comparison") ? "active" :(location.pathname==="/care-package-booking") ? "active" :(location.pathname==="/care-booking-confirmedd") ? "active" :""}`} > */}
            <li className="list-inline-item">
              <Link
                className="d-flex align-items-center flex-column justify-content-center"
                to="/care-package-categories"
              >
                <div className="image-holder">
                  <i className="mhc-icon icon-care-packages"></i>
                </div>
                <span>
                  Care <br /> Packages
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </section>
      <footer className={`py-4 py-md-5 `}>
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-3">
              <h2>MyHealthcare</h2>
              <ul className="list-unstyled mt-3 mb-0">
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                <li>
                  <Link to="/insights">Insights</Link>
                </li>
                <li>
                  <Link to="/faq">FAQs</Link>
                </li>
                <li>
                  <Link to="/career">Careers</Link>
                </li>
                <li>
                  <Link to="/terms-of-use">Terms of use</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions">Terms and Conditions</Link>
                </li>
              </ul>
            </div>
            {/* end col */}
            <div className="col-sm-6  col-md-6 col-lg-3">
              <h2>Our Services</h2>
              <ul className="list-unstyled mt-3 mb-0">
                <li>
                  <Link to="/book-consult">Book Consult</Link>
                </li>
                <li>
                  <Link to="/book-package-or-test/test">Book Tests</Link>
                </li>
                <li>
                  <Link to="/order-medicine">Order Medicine</Link>
                </li>
                <li>
                  <Link to="/care-package-categories">Care Programs</Link>
                </li>
                <li>
                  <Link to="/homecare-services">Book Homecare</Link>
                </li>
              </ul>
            </div>
            {/* end col */}
            <PartnerHospitals />
            {/* end col */}
            <div className="col-sm-6  col-md-6 col-lg-3 mt-4 mt-lg-0">
              <h2>Contact Us</h2>
              <ul className="list-unstyled mt-3 mb-0">
                <li>
                  <Link to="/contact-us?type=patient">I'm a Patient</Link>
                </li>
                <li>
                  <Link to="/contact-us?type=doctor">I'm a Doctor</Link>
                </li>
                <li>
                  <Link to="/contact-us?type=organization">I'm a Organisation</Link>
                </li>
              </ul>
            </div>
            {/* end col */}
          </div>

          <div className="row mt-3 mt-md-5 align-items-center">
            <div className="col-md-5 col-lg-6 ">
              <ul className="list-inline mb-0">
                <li className="list-inline-item me-3">
                  <span>Follow Us</span>
                </li>
                <li className="list-inline-item me-3 mt-2 mt-md-0">
                  <a href="https://www.facebook.com/MyHealthcareEcosystem" className="icon"target={'_blank'}>
                    <img src={facebook} />
                  </a>{" "}
                  <a href="https://www.instagram.com/myhealthcare.life/" className="icon" target={'_blank'}>
                    <img src={instagram} />
                  </a>{" "}
                  <a href="https://www.linkedin.com/company/ivpl/" className="icon" target={'_blank'}>
                    <img src={linkdin} />
                  </a>{" "}
                  <a href="https://twitter.com/MyHLTHcare" className="icon" target={'_blank'}>
                    <img src={twitter} />
                  </a>
                </li>
              </ul>
            </div>
              <DownloadOurApp />
          </div>
        </div>
      </footer>
      <div className="copy-right">
        <p>
          Copyright &copy; 2022 · All Rights Reserved · MyHealthcare
          Technologies Private Limited
        </p>
      </div>
      <button type="button" className={`scroll-to-top ${showTopBtn === true ? 'd-block' : 'd-none'}`} onClick={goToTop}>
        <i className="mhc-icon icon-up-open-big"  ></i>
      </button>
    </React.Fragment>
  );
}

export default Footer;
