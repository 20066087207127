import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SearchIcon from "../../assets/images/mhc-search-icon.svg";
import { clearPackageDetailReducer, setPackageDetailReducer } from "../../redux/slice/package-detail";
import { selectedPincodeReducer } from "../../redux/slice/selected-pincode";
import { setVendorReducer } from "../../redux/slice/vendor";

const SearchFormWidget = ({
  searchType,
  placeHolder = "",
  setKeyword = "",
  searchResult,
  sId = 0,
  isProcessing = false,
  btnDisplay = true || false,
}) => {
  const [dropdown, setDropdown] = useState(false);
  const [closebtn, setClosebtn] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState(null);
  
  useEffect(() => {
    setSearchData(searchResult);
  }, [searchResult]);

  function handleDropdown() {
    setDropdown(true);
    setClosebtn(true);
  }

  function handleCloseDropdown(type = '') {
    if (type === 'inside') return false;

    setDropdown(false);
    setClosebtn(false);
    setSearchText("");
    setKeyword("");
    setSearchData(null);
  }

  function handleChange(e) {
    setSearchText(e.target.value);
    setKeyword(e.target.value);
  }


  const dispatch = useDispatch();

  const vendorId = useSelector((state) => state.vendor.value);
  const geoCoordinates = useSelector((state) => state.location.value);
  const selectedPincode = useSelector((state) => state.selectedPincode.value);

  const [packageDetail, setPackageDetail] = useState({});
  
  useEffect(async () => {
    if (!selectedPincode) {
      if (geoCoordinates) {
        dispatch(selectedPincodeReducer(geoCoordinates.pin_code));
      }
    }
  }, [selectedPincode, geoCoordinates]);

  function setForwardData(id, packageDetail) {
    dispatch(setVendorReducer(id));
    dispatch(clearPackageDetailReducer());
    dispatch(setPackageDetailReducer({ ...packageDetail, selectedPincode }));
  }

  return (
    <React.Fragment>
      {/*  onBlur={(e) => handleCloseDropdown()} */}
      <div className="Mhc-search w-100">
        <div className="input-group">
          <span className="input-group-text">
            <img src={SearchIcon} alt="" />
          </span>
          <input
            type="text"
            onKeyUp={() => handleDropdown()}
            value={searchText}
            onChange={(e) => handleChange(e)}
            className="form-control"
            placeholder={placeHolder} />
        </div>
        <button className={`btn btn-primary  ${btnDisplay === true ? "d-block" : "d-none"}`} onClick={() => handleDropdown()}>
          Search
        </button>
        <button className={`search-close mrr ${closebtn === false ? "d-none" : "d-block"}`} onClick={() => handleCloseDropdown()}>
          <i className="mhc-icon icon-cancel text-primary"></i>
        </button>

        <div className={`mhc-search-dropdown search-for-booking ${dropdown === false ? "d-none" : "d-block"}`}>
          {
            (isProcessing && (searchText !== '')) ?
              <div className="search-header">Searching...</div>
              : searchData === null ?
                <div className="search-header">No data found!</div>
                : <>
                  {
                    (searchType && (searchType.includes('hospitals') || searchType.includes('all')) && searchData && searchData.hospitals) ? (
                      <>
                        <div className="search-header">Hospitals</div>
                        <div className="search-body">
                          <ul>
                            {
                              searchData.hospitals && Array.isArray(searchData.hospitals) && searchData.hospitals.map((hospital, index) => {
                                return (
                                  <li key={index}>
                                    <Link to={`/book-consult/hospitals/${hospital.slug}/${hospital.id}`} onClick={(e) => handleCloseDropdown('inside')}>
                                      <div className="d-flex align-items-center">
                                        <div className="text-cricle">{hospital.display_name.charAt(0)}</div>
                                        <div>
                                          <p className="m-0">{hospital.display_name}</p>
                                          <span>{hospital.address}</span>
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </div>
                      </>
                    ) : ''
                  }

                  {/* {
                    (searchType && (searchType.includes('clinics') || searchType.includes('all')) && searchData && searchData.clinics) ? (
                      <>
                        <div className="search-header">Clinics</div>
                        <div className="search-body">
                          <ul>
                            {searchData.clinics && Array.isArray(searchData.clinics) && 
                              searchData.clinics.map((clinic, index) => {
                                console.log('clinic: ', clinic);
                                return (
                                  <li key={index}>
                                    <Link
                                      to={`/book-consult/clinics/${clinic.slug}/${clinic.id}`} onClick={(e) => handleCloseDropdown('inside')}>
                                      <div className="d-flex align-items-center">
                                        <div className="text-cricle">{clinic.clinic_name.charAt(0)}</div>
                                        <div>
                                          <p className="m-0">{clinic.clinic_name}</p>
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </div>
                      </>
                    ) : ''
                  } */}

                  {
                    (searchType && (searchType.includes('specialities') || searchType.includes('all')) && searchData && searchData.specialities) ? (
                      <>
                        <div className="search-header">Specialities</div>
                        <div className="search-body">
                          <ul>
                            {searchData.specialities && Array.isArray(searchData.specialities) &&
                              searchData.specialities.map((speciality, index) => {
                                return (
                                  <li key={index}>
                                    <Link
                                      to={`/book-consult/specialities/${speciality.slug}/${speciality.id}`} onClick={(e) => handleCloseDropdown('inside')}>
                                      <div className="d-flex align-items-center">
                                        <div className="text-cricle">{speciality.name.charAt(0)}</div>
                                        <div>
                                          <p className="m-0">{speciality.name}</p>
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </div>
                      </>
                    ) : ''
                  }

                  {
                    (searchType && (searchType.includes('health_offers') || searchType.includes('all')) && searchData && searchData.health_offers) ? (
                      <>
                        <div className="search-header">Packages</div>
                        <div className="search-body">
                          <ul>
                            {searchData.health_offers && Array.isArray(searchData.health_offers) &&
                              searchData.health_offers.map((item, index) => {
                                return (
                                  <li key={index}>
                                    <Link
                                      to={`/care-packages/${item.category_name.toLowerCase().trim().replaceAll("_", "-")}/${item.id}`} onClick={(e) => handleCloseDropdown('inside')}>
                                      <div className="d-flex align-items-center">
                                        <div className="text-cricle">{item.category_name.charAt(0)}</div>
                                        <div>
                                          <p className="m-0">{item.category_name.replaceAll('_', ' ')}</p>
                                          <span>{item.name}</span>
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </div>
                      </>
                    ) : ''
                  }

                  {
                    (searchType && (searchType.includes('doctors') || searchType.includes('all')) && searchData && searchData.doctors && Array.isArray(searchData.doctors)) ? (
                      <>
                        <div className="search-header">Doctors</div>
                        <div className="search-body">
                          <ul>
                            {
                              searchData.doctors.map((doctor, index) => {
                                console.log('doctor: ', doctor);
                                return (
                                  <li key={index}>
                                    <Link
                                      to={`/book-consult/doctors/${doctor.slug}/${doctor.id}/${doctor.hospital.id}/${doctor.doctor_specialities_slug&& doctor.doctor_specialities_slug[0] && doctor.doctor_specialities_slug[0].id}/0?consultType=doctors`} onClick={() => handleCloseDropdown('inside')}>
                                      <div className="d-flex align-items-center">
                                        <div className="text-cricle">{doctor.name.charAt(0)}</div>
                                        <div>
                                          <p className="m-0">{doctor.name}</p>
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </div>
                      </>
                    ) : ''
                  }

                  {
                    (searchType && (searchType.includes('diagnostics') || searchType.includes('all')) && searchData && searchData.diagnostics && Array.isArray(searchData.diagnostics)) ? (
                      <>
                        <div className="search-header">Diagnostics</div>
                        <div className="search-body">
                          <ul>
                            {
                              searchData.diagnostics.map((diagnostic, index) => {
                                return (
                                  <li key={index}>
                                    <Link
                                      onClick={(e) => {
                                          setForwardData(diagnostic.vendor_id, diagnostic);
                                          handleCloseDropdown('inside');
                                        }
                                      }
                                      to={`/package-booking/${diagnostic.slug}/${diagnostic.id}`}>
                                      <div className="d-flex align-items-center">
                                        <div className="text-cricle">{diagnostic.name.charAt(0)}</div>
                                        <div>
                                          <p className="m-0">{diagnostic.name}</p>
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </div>
                      </>
                    ) : ''
                  }
                </>
          }
        </div>
      </div>
    </React.Fragment>
  );
}

export default SearchFormWidget;
