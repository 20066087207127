import React, { useState, useEffect } from "react";
import BookingSection from "./BookingSection";
import {
  doctorProfileById,
  getHospitals,
  doctorAvailabilityById,
  specialityInfoById,
} from "../../actions/get-calls";
import { Container, Accordion } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import {
  changeDayString,
  decodeString,
  encodeString,
} from "../../constants/app-constants";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";

export default function DectorProfile() {
  const { dId, hId, sId, cId, hospitalSlug, specialitySlug } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const type = params.get("consultType");

  const [loadmore, setLoadmore] = useState(false);
  const [docProfile, setDocProfile] = useState(false);
  const [hospitalId, setHospitalId] = useState(hId);
  const [consultId, setConsultId] = useState(cId);
  const [hospital, setHospital] = useState();
  const [docAvail, setDocAvail] = useState(null);
  const [availableDays, setAvailableDays] = useState(null);
  const [doctorAvailableDays, setDoctorAvailableDays ] = useState();
  const [specialityName, setSpecialityName] = useState();
  
  useEffect(()=>{
    setHospitalId(hId)
    // changeHospital(hId)
  },[hId])
  
  useEffect(async () => {
    const doctors = await doctorProfileById(dId, hospitalId);
    const hospitals = await getHospitals(hospitalId);
    const avails = await doctorAvailabilityById(dId);

    const speciality = await specialityInfoById(sId);

    setSpecialityName(speciality.data.speciality.name);

    setDocProfile(doctors.data.doctor);

    setHospital(hospitals.data.hospital);
    setDocAvail(avails.data.hospitals);

    if (avails && avails.data && avails.data.hospitals) {
      setAvailableDays(avails.data.hospitals[0].availability);
    }
  }, [hospitalId, consultId]);

  const handleLoad = () => {
    setLoadmore(!loadmore);
  };

  useEffect(()=> {
    setDoctorAvailableDays( docAvail?.filter(value=>value.id ===hospitalId ) )
        if( docAvail?.filter(value=>value.id ===hospitalId ).length > 0 )
        setAvailableDays(docAvail?.filter(value=>value.id ===hospitalId )[0].availability);
  } ,[docAvail])
  
  function changeHospital(item) {
    setHospital(item);
    setHospitalId(item.id)
    // window.history.replaceState(
    //   {},
    //   document.getElementsByTagName('title')[0].innerHTML,
    //   `/doctor-profile/${dId}/${id}/${sId}/${consultId}`
    // );
  }

  const breadCrumbData = [
    { type: 1, url: "/", name: "Home" },
    { type: 1, url: `/book-consult/${type}`, name: "Book Consult" },
    // ...(type === 'clinics'?[{
    //   type:1,
    //   url:'/book-consult/clinics',
    //   name:'Clinics'
    // }]:[]),
    // ...(type === 'doctors'?[{
    //   type:1,
    //   url:'/book-consult/doctors',
    //   name:'Doctors'
    // }]:[]),
    ...((type ==='hospitals') ? [{
      type: 1,
      url:
        type === "hospitals"
          ? `/book-consult/hospitals/${hospitalSlug}/${hId}`
          : "/book-consult/consult-doctor/" +
            encodeString(hospital === undefined ? "" : hospital.name) +
            "/" +
            hospitalId +
            "/" +
            sId,
      name: hospital === undefined ? "" : hospital.name,
    }]:[]),
    ...(type !== 'doctors'?[{
      type: type === "hospitals" ? 1 : 4,
      url:
        type === "hospitals"
          ? `/book-consult/hospitals/${hospitalSlug}/${specialitySlug}/${hId}/${sId}`
          : `/book-consult/specialities/${sId}`,
      name: specialityName,
    }]:[]),
    {
      type: 3,
      url: "",
      name: docProfile === undefined ? "loading.." : docProfile.name,
    },
  ];

  return (
    <React.Fragment>
      <Container>
        <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />

        <div className="row ">
          <div className="col-lg-6">
            <div className="mhc-dector-profile-card">
              <div className="img-holder">
                {docProfile === undefined ? (
                  "loading.."
                ) : (
                  <img src={docProfile.profile_image} alt="img" />
                )}
              </div>
              <div className="ms-3">
                <p className="m-0 fw-bold  text-secondary text-tittle">
                  {docProfile === undefined ? "loading.." : docProfile.name}
                </p>
                <p className="sub-text m-0">
                  <span className="fw-bold">
                    {docProfile === undefined
                      ? "loading.."
                      : docProfile.designation}
                  </span>
                  <br /> Exp. {docProfile && docProfile.experience}+ Yrs
                </p>
                <p className="mb-0 mt-2 fw-bold">
                  {hospital === undefined ? "loading.." : hospital.name}
                </p>
                <ul className="list-unstyled d-flex flex-wrap mb-0 text-capitalize fs-6">
                  <li className="pe-1">
                    <span className="text-secondary">Available: </span>
                  </li>
                  {docAvail === undefined ? (
                    <h6>loading..</h6>
                  ) : (
                    (doctorAvailableDays &&
                    doctorAvailableDays.length  > 0) ? 
                    doctorAvailableDays[0]?.availability.map((item, idx) => (
                      <li key={item} className="px-1">
                        <span className="text-secondary">
                          {changeDayString(item)}
                          {idx + 1 !== doctorAvailableDays[ 0].availability.length && ",   "}
                     
                        </span>
                      </li>
                    ))
                    :docAvail &&
                    docAvail.length > 0 &&
                    docAvail[0].availability.map((item, idx) => (
                      <li key={item} className="px-1">
                        <span className="text-secondary">
                          {changeDayString(item)}
                          {idx + 1 !== docAvail[0].availability.length && ", "} 
                        </span>
                      </li>
                    ))
                  )}
                </ul>
              </div>
            </div>

            <ul className="list-unstyled d-flex mt-3 mb-0">
              {docAvail &&
                docAvail.length > 0 &&
                docAvail.map((item, index) => (
                  <li key={index}>
                    <div
                      onClick={(e) => changeHospital(item)}
                      className={
                        item.id == hospitalId
                          ? "mhc-profile-dector-card active"
                          : "mhc-profile-dector-card"
                      }
                    >
                      <img src={item.image} alt={item.name} />
                      <p>{item.name}</p>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
          <div className="col-lg-6 mt-4 mt-lg-0">
            <h4 className="fs-7 text-secondary">Profile</h4>
            <p className="mhc-dector-profile-text" style={{ maxHeight: loadmore ? 'unset' : '140px', overflow: loadmore ? 'unset' : 'hidden' }}>
              {docProfile &&
                docProfile.summaries &&
                Array.isArray(docProfile.summaries) &&
                docProfile.summaries.length > 0 && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: docProfile.summaries[0].text,
                    }}
                  ></p>
                )}
            </p>

            <Accordion defaultActiveKey="" className="more-details-tab">
              <Accordion.Item eventKey="0">
                <Accordion.Body>
                  <h4 className="fs-7 text-secondary mt-3 mb-0">
                    Qualification
                  </h4>
                  <p
                    className="mhc-dector-profile-text"
                    dangerouslySetInnerHTML={{
                      __html: docProfile.qualifications,
                    }}
                  ></p>
                  <h4 className="fs-7 text-secondary mt-3 mb-3">Specialties</h4>
                  <p>
                    {docProfile.specialities &&
                      Array.isArray(docProfile.specialities) &&
                      docProfile.specialities.join(",")}
                  </p>
                  <ul className="list-unstyled d-flex flex-wrap mb-0">
                    {docProfile === undefined ? (
                      <h6>loading..</h6>
                    ) : (
                      docProfile &&
                      docProfile.specialities.map((item, idx) => {
                        <li key={idx}>
                          <span className="btn btn-outline-secondary rounded-1 me-3 mb-3">
                            {item}
                          </span>
                        </li>;
                      })
                    )}
                  </ul>
                </Accordion.Body>
                <Accordion.Header onClick={handleLoad}>
                  {loadmore === false ? (
                    <span>More...</span>
                  ) : (
                    <span>less</span>
                  )}
                </Accordion.Header>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
        <div className="border-bottom border-light w-100 transition mt-3 mt-lg-4"></div>
        <BookingSection
          hospitalId={hospitalId}
          setConsultId={setConsultId}
          availableDays={availableDays}
          docProfile={docProfile}
        />
      </Container>
    </React.Fragment>
  );
}