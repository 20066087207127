import React, { useEffect, useLayoutEffect, useState } from "react";
import { Container, Accordion } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { specialitiesListByHospital, getHospitals } from "../../actions/get-calls";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";
import { encodeString } from "../../constants/app-constants";
import LodaingImage from "../../assets/images/loader.gif"

const ConsultHospital = () => {
  const { hId } = useParams();
  const [special, setSpecial] = useState(null);
  const [otherLocations, setOtherLocations] = useState();
  const [hospitalInfo, setHospitalInfo] = useState(null);

  useEffect(() => {
    getHospitals(hId)
      .then(function (response) {
        let res = response.data;
        setHospitalInfo(res.hospital);
      })
      .catch(function () { });

    specialitiesListByHospital(hId)
      .then(function (response) {
        let res = response.data;
        setSpecial(res.specialities);
        setOtherLocations(res.other_locations);
      })
      .catch(function () { });
  }, []);
  
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  
  const breadCrumbData = [
    { type: 1, url: '/', name: 'Home' },
    { type: 1, url: '/book-consult/hospitals', name: 'Book Consult' },
    { type: 3, url: '', name: hospitalInfo ? hospitalInfo.name : 'Hospital Name' }
  ];

  return (
    <>
      {(!special || !hospitalInfo) ? (
        <>
          <div className="mhc-Loding text-center my-5">
            <img src={LodaingImage} alt="loader" />
          </div>
        </>
      ) : (
        <React.Fragment>
          <Container>
              <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />

            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-center mhc-partner">
                  <div className="logo">
                    <img
                      src={(hospitalInfo && hospitalInfo.images) && hospitalInfo.images[0]}
                      alt={""}
                    />
                  </div>
                  <div>
                    <p className="fs-6 fw-bold partner-hopsitalName">
                      {hospitalInfo.name}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Accordion className="mhc-mobile-tabs mt-4">
              <Accordion.Item eventKey="0">
                <Accordion.Header>{hospitalInfo.name}</Accordion.Header>
                <Accordion.Body>
                  <h3 className="mb-0">{hospitalInfo.name}</h3>
                  {hospitalInfo.about_hospital && (<p className="mt-1">{hospitalInfo.about_hospital}</p>)}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {!special ? (
              <div className="mhc-Loding text-center my-5">
                <img src="../loader.gif" alt="loader" />
              </div>
            ) : (
              <>
                <h1 className="fs-5 my-3 my-lg-4 ">Specialities</h1>
                <Accordion className="specialitiestabs hopsitaltab">
                  <div className="row ">
                    {special.map((item) => {
                      return (
                        <>
                          <div className="col-lg-4" key={item.id}>
                            <Accordion.Item
                              eventKey={item.id}
                              className="mb-4"
                            >
                              <Accordion.Header>
                                <Link to={`/book-consult/hospitals/${hospitalInfo.slug}/${item.slug}/${hospitalInfo.id}/${item.id}`}>
                                  <img src={item.icon} alt={item.name} />
                                  {item.name}  
                                </Link>
                              </Accordion.Header>
                            </Accordion.Item>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </Accordion>
              </>
            )}

            {
              otherLocations ?
              <>
                    <h1 className="fs-6 my-3 my-lg-4 ">
                      {hospitalInfo.name} in other locations
                    </h1>
                    <ul className="list-unstyled d-flex flex-wrap mhc-hopistal-list mb-0 mt-3">
                      {otherLocations.map((location) => {
                        return <li className=" mb-3" onClick={() => {
                          window.location.replace(`/book-consult/hospitals/${location.slug}/${location.id}`)
                        }}>
                          <div className="mhc-hospital">
                            <p className="m-0">{location.name}</p>
                            <p className="m-0 text-primary">{location.address}</p>
                          </div>
                        </li>
                      })}
                    </ul>
              </> : ""
            }
          </Container>
        </React.Fragment>
      )}
    </>
  );
};

export default ConsultHospital;
