import React, { useEffect, useLayoutEffect, useState } from "react";
import { Accordion, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { thyrocareDetail } from "../../actions/get-calls";
import lalpath from "../../assets/dust/lalpathlabs.webp";

import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";
import { clearPackageDetailReducer, setPackageDetailReducer } from "../../redux/slice/package-detail";
import { selectedPincodeReducer } from "../../redux/slice/selected-pincode";
import { setVendorReducer } from "../../redux/slice/vendor";

const PackageTestDetail = () => {
  const dispatch = useDispatch();

  const geoCoordinates = useSelector((state) => state.location.value);
  const selectedPincode = useSelector((state) => state.selectedPincode.value);

  const [packageDetail, setPackageDetail] = useState({});
  const { dId } = useParams();

  useEffect(async () => {
    if (!selectedPincode) {
      if (geoCoordinates) {
        dispatch(selectedPincodeReducer(geoCoordinates.pin_code));
      }
    }
  }, [selectedPincode, geoCoordinates]);

  useEffect(async () => {
    if (selectedPincode) {
      const _thyroProduct = await thyrocareDetail(dId, selectedPincode);

      if (_thyroProduct && _thyroProduct.data && _thyroProduct.data.details) {
        let modifiedChild = _thyroProduct.data.details[0].childs.reduce(function (
          r,
          a
        ) {
          r[a.group_name] = r[a.group_name] || [];
          r[a.group_name].push(a);
          return r;
        },
          Object.create(null));

        let sortedChild = [];
        for (var x in modifiedChild) {
          sortedChild.push(modifiedChild[x]);
        }

        let mChild = { mData: sortedChild };
        _thyroProduct.data.details[0] = {
          ..._thyroProduct.data.details[0],
          ...mChild,
        };
      }

      setPackageDetail(_thyroProduct.data);
    }
  }, [selectedPincode]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const breadCrumbData = [
    { type: 1, url: "/", name: "Home" },
    { type: 2, name: 'Book Package' },
    { type: 2, name: packageDetail.name },
  ];

  return (
    <>
      <Container className="mhc-package-modal">
        <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />

        {
          (packageDetail && packageDetail.details) ?
            <>
              <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center  mb-4">
                <div>
                  <h6 className="m-0">{packageDetail.name}</h6>
                  <p className="m-0">Profile Includes ({packageDetail.details[0].testCount} Parameters)</p>
                </div>

                <div className="d-flex mt-3 mt-md-0 flex-column flex-md-row align-items-start align-items-md-center">
                  <img src={packageDetail.details[0].diagnosis_logo} className="test-icon" />
                </div>
              </div>

              <Accordion defaultActiveKey="" className="">
                <Accordion defaultActiveKey={0}>
                  {packageDetail.details[0].mData.map((xItem, index) => {
                    return (
                      <Accordion.Item eventKey={index} key={index}>
                        <Accordion.Header>
                          {xItem[0].group_name} (includes {xItem.length} tests)
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul className="m-0">
                            {xItem &&
                              xItem.map((mItem, mIndex) => {
                                return <li key={mIndex}>{mItem.name}</li>;
                              })}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </Accordion>
              <div className="w-100 text-center my-4">
                <Link
                  to={`/book-package-or-test/packages/${packageDetail ? packageDetail.slug : "_"}/package-booking`} className="btn btn-primary rounded-1">
                  Book Package
                </Link>
              </div>
            </>
            : <div className="mhc-Loding text-center my-5">
              <img src="../../loader.gif" alt="loader" />
            </div>
        }
      </Container>
    </>
  );
};

export default PackageTestDetail