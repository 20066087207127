import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Breadcrumb from '../../component/breadcrumb';
import MultiImageUploader from "../../component/multi-image-uploader";
import { setBookingSourceReducer } from '../../redux/slice/booking-source';
import { bookHomecarePackage } from '../../actions/post-call';
import { dateFormatIntoYYYYMMDD, decodeString } from '../../constants/app-constants';
import { useParams } from 'react-router';
import BreadcrumbWithUrl from '../../component/breadcrumb/breadcrumb-with-url';

function HomeCareServiceBooking() {
    const dispatch = useDispatch();
    const packageDetail = useSelector((state) => state.packageDetail.value);
    const bookingSource = useSelector((state) => state.bookingSource.value);
    const selectedUser = useSelector((state) => state.selectedUser.value);

    const { categoryName, subCatName, categoryId, subCategoryId } = useParams();

    const [patientName, setPatientName] = useState('');
    const [DOB, setDOB] = useState(null);
    const [patientMobile, setPatientMobile] = useState('');
    const [gender, setGender] = useState('');
    const [prescription, setPrescription] = useState(null);
    const [bookingDetail, setBookingDetail] = useState(null);
    const [isBooked, setIsBooked] = useState(false);

    useEffect(() => {
        if (selectedUser) {
            setPatientName(selectedUser.full_name);
            if (selectedUser.dob) {
                setDOB(new Date(selectedUser.dob));
            }
            setPatientMobile(selectedUser.mobile_number);
            setGender(((selectedUser.gender === 'Female') ? 2 : (selectedUser.gender === 'Male' ? 1 : 3)))
        } else {
            setDOB(new Date());
        }
    }, [])

    useEffect(() => {
        let currentBookingSource = window.location.pathname.split('/').pop();
        if (bookingSource) {
            if (bookingSource !== currentBookingSource) {
                dispatch(setBookingSourceReducer(currentBookingSource));
            }
        } else {
            dispatch(setBookingSourceReducer(currentBookingSource));
        }
    }, [bookingSource])

    async function bookHomeCare() {
        if (!patientName) {
            return showToast("Name is mandatory!");
        } else if (!patientMobile) {
            return showToast("Mobile is mandatory!");
        } else if (!DOB) {
            return showToast("Date of birth is mandatory!");
        } else if (!gender) {
            return showToast("Gender is mandatory!");
        }
        // else if (!prescription) {
        //   return showToast("Please upload reports!");
        // }

        const formData = new FormData();

        formData.append('test_id', packageDetail ? packageDetail.id : null);
        formData.append('hospital_id', packageDetail ? packageDetail.hospital_id : null);
        formData.append('patient_name', patientName);
        formData.append('mobile_no', patientMobile);
        formData.append('dob', dateFormatIntoYYYYMMDD(DOB));

        if (prescription) {
            for (const key in prescription) {
                formData.append('file_upload[]', new Blob([prescription[key]], {
                    type: 'application/json'
                }));
            }
        }

        bookHomecarePackage(formData)
            .then(function (response) {
                let res = response.data;
                if (res && res.errors) {
                    res.errors.map((error) => {
                        return showToast(error.message);
                    })
                }

                setBookingDetail(res);
                setIsBooked(true);

                // setPatientMobile('');
                // setPatientName('');
                // setGender('');
                // setDOB(null);
                // setPrescription(null);

                toast.success(res.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false
                });

                setTimeout(() => {
                    setIsBooked(false);
                    window.location.reload();
                }, 3000);
            }).catch(function (err) { });
    }

    function showToast(str) {
        toast.error(str, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false
        });
    }

    const breadCrumbData = [
        { type: 1, url: '/', name: 'Home' },
        { type: 1, url: '/homecare-services', name: 'Homecare Services' },
        { type: 1, url: `/homecare-services/${categoryName}/${categoryId}`, name: categoryName.replaceAll('-', ' ') },
        { type: 4, url: '', name: subCatName.replaceAll('-', ' ') },
        { type: 5, url: '', name: 'Booking' },
    ];

    return (
        <React.Fragment>
            <div className="container mb-5">
                <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />

                <div className="row mt-3">
                    {
                        isBooked ?
                            <div className="col-12">
                                <div className="alert alert-c-primary text-center">Thanks! Your request has been submitted successfully. Our team will get in touch with you shortly.</div>
                            </div>
                            : ''
                    }
                    <div className="col-lg-6">
                        <div className="HomeCare-Package-card">
                            <div className="Homecare-package-header py-2">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="mb-0">{packageDetail ? packageDetail.title : ""}</h6>
                                    <div>
                                        <p className="text-secondary fs-13 mb-0">Starting at </p>
                                        <p className=" fs-13 text-primary text-end mb-0 fw-bolder text-nowrap">INR {packageDetail ? packageDetail.discount_price : 0}</p>
                                    </div>
                                </div>

                            </div>
                            <div className="homecare-package-body pt-3">
                                <ul className="list ">
                                    <li className="active"><span>Service Inclusions</span></li>
                                    {
                                        packageDetail ? packageDetail.features.map((item, index) => {
                                            return <li key={index}>{item}</li>
                                        }) : ""
                                    }
                                </ul>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-6 mt-4 mt-lg-0">
                        <div className="HomeCare-Package-card">
                            <div className="Homecare-package-header">
                                <h6 className="mb-0">Homecare Patient Form </h6>
                            </div>
                            <div className="homecare-package-body p-4">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-floating mb-4">
                                            <input type="text" className="form-control" id="name" autoComplete="off" placeholder="Enter name" value={patientName} onChange={(e) => setPatientName(e.target.value)} />
                                            <label htmlFor="name"> Name <sup>*</sup></label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating mhc-dob date mb-4 bg-white">
                                            <DatePicker
                                                name="dob"
                                                id="dob"
                                                calendarClassName="mhc-input-date"
                                                dateFormat="dd/MM/yyyy"
                                                selected={DOB}
                                                onChange={(date) => setDOB(date)}
                                                calendarStartDay={1}
                                                formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                                                dropdownMode="select"
                                                showMonthDropdown
                                                showYearDropdown
                                                adjustDateOnChange
                                            />

                                            <i className="mhc-icon icon-calendar"></i>
                                            <label htmlFor="Dob" className={`${DOB != null ? 'dob' : ''}`}>Date Of Birth <sup>*</sup></label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating mb-4">
                                            <input type="tel" className="form-control" maxLength="10" id="Mobile" placeholder="Mobile" value={patientMobile} onChange={(e) => setPatientMobile(e.target.value)} />
                                            <label htmlFor="name">Mobile <sup>*</sup></label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="d-flex flex-column flex-lg-row justify-content-start align-items-lg-center">
                                            <span className="fw-bold ">Gender  <sup className="text-primary">*</sup></span>
                                            <ul className="list-unstyled mb-0 mt-4 mt-lg-0 d-flex align-items-center">
                                                <li>
                                                    <div className="form-check mt-1 ms-0 ms-lg-4" >
                                                        <input className="form-check-input" type="radio" name="gender" id="Male" value={1} onChange={(e) => setGender(1)} checked={gender === 1} />
                                                        <label className="form-check-label" htmlFor="Male">
                                                            Male
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check mt-1 ms-3 ms-sm-4">
                                                        <input className="form-check-input" type="radio" name="gender" id="Female" value={2} onChange={(e) => setGender(2)} checked={gender === 2} />
                                                        <label className="form-check-label" htmlFor="Female">
                                                            Female
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check mt-1 ms-3 ms-sm-4">
                                                        <input className="form-check-input" type="radio" name="gender" id="Others" value={3} onChange={(e) => setGender(3)} checked={gender === 3} />
                                                        <label className="form-check-label" htmlFor="Others">
                                                            Others
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <p className="fs-6 mb-0 mt-2">Upload any relevant reports that may be used for assessment, if necessary</p>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <MultiImageUploader setPrescri={setPrescription} />
                                        <button onClick={(e) => bookHomeCare()} className="btn btn-outline-primary rounded-1 mt-4">Request Homecare</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    )


}

export default HomeCareServiceBooking;