export const cipherKey = "__myhealthcare__";
export const baseURL =
  "https://prerelease.mhappapis.vc.myhealthcare.co/api/patients/web/v2/";

export const primaryToken =
  "eyJpdiI6IllkMVdCSVo5QnVacitJQlpcL3NJOTJBPT0iLCJ2YWx1ZSI6IjRERHdxWlB2V0JDYnhzcFwvOEFBbzk1MUFCWllyaFZzczZrQnZ5dmRuYk1VPSIsIm1hYyI6ImVhNGJhZWRjZjI4YmNjOTNiNzEyNmEyYTE2ZDVkYWI3NmY2YWM1NmYxZDNhZTlmNjlhNWFlNTBkY2QxNGY1M2EifQ==";
export const secondaryToken =
  "eyJpdiI6ImxUeXIra0VIMDdrb1hSY1F3Z2UyXC9nPT0iLCJ2YWx1ZSI6Imh1TmZDWWdLRTh2cVBVTHdrR3A4dkE9PSIsIm1hYyI6IjdjNzAxMTY4OTRkNGExYmMzN2U5N2VmZDZlNTQwY2I5YmNjYjU2ZTAwYThhZjBkNzk5YWMwM2Y1YTJiZGM3MjgifQ==";
export const tertiaryToken =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvbWhhcHBhcGlzLnZjLm15aGVhbHRoY2FyZS5jb1wvIiwiaWF0IjoxNjM1OTE3NzE3LCJuYmYiOjE2MzU5MTc3MTcsImV4cCI6MTY0MzY5MzcxNywidWlkIjo3NTM2LCJ0IjoicCJ9.hOkN4a6uq2WpbGwGzbOvyJXkcjH9yvENTbcDOwF4DPo";

export const payLaterLink =
  "https://prerelease.mhappapis.vc.myhealthcare.co/api/patients/web/v2/doctors/thyro-thank-you/";

export const maritalStatusList = ["Single", "Married", "Divorced", "Widow"];
export const genderList = ["Male", "Female", "Other"];
export const relationList = [
  "Father",
  "Mother",
  "Brother",
  "Sister",
  "Son",
  "Daughter",
  "Grand Son",
  "Grand Daughter",
  "Grand Father",
  "Grand Mother",
];

export const storageConstant = {
  call_key: window.btoa("callKey").replaceAll("=", ""),
  switch_key: window.btoa("switchKey").replaceAll("=", ""),
  logged_user: window.btoa("loggedUser").replaceAll("=", ""),
  selected_user: window.btoa("selectedUser").replaceAll("=", ""),
  user_profile: window.btoa("userProfile").replaceAll("=", ""),
};

export function encodeSFA(arr) {
  return window.btoa(JSON.stringify(arr)).replaceAll("=", "");
}

export function decodeSTA(str) {
  return JSON.parse(window.atob(str));
}

export function encodeString(str) {
  return window.btoa(unescape(encodeURIComponent(str))).replaceAll("=", "");
}

export function decodeString(str) {
  return window.atob(str);
}

export function changeDayString(day) {
  let originalDay = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ].indexOf(day);
  let tempDay = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][originalDay];
  return tempDay;
}

export function getDayNumber(day) {
  let originalDay = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ].indexOf(day);
  return originalDay;
}

export function calculateAge(dob) {
  // var today = new Date();
  // var birthDate = new Date(dob);
  // var age_now = today.getFullYear() - birthDate.getFullYear();
  // var m = today.getMonth() - birthDate.getMonth();

  // if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age_now--;
  // // if (age_now === NaN) age_now = 0;
  // console.log('age_now: ', age_now);
  // return age_now;
    if(dob){
        var from = dob.split("/");
        var birthdateTimeStamp = new Date(from[2], from[1] - 1, from[0]);
        var cur = new Date();
        var diff = cur - birthdateTimeStamp;
        // This is the difference in milliseconds
        var currentAge = Math.floor(diff / 31557600000);
        return currentAge;
    }else{
        return 0
    }

  // Divide by 1000*60*60*24*365.25
}

export function dateFormatIntoDDMMYYYY(x) {
  let today = new Date(x);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1;
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  return dd + "/" + mm + "/" + yyyy;
}

export function dateFormatIntoMMDDYYYY(x) {
  let today = new Date(x);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1;
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  return mm + "-" + dd + "-" + yyyy;
}

export function dateFormatIntoYYYYMMDD(x) {
  let today = new Date(x);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1;
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  return yyyy + "-" + mm + "-" + dd;
}

export function timeConvertIntoAM_PM(time) {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? " AM" : " PM";
    time[0] = +time[0] % 12 || 12;
  }
  return time.join("");
}

export function currentTimeConvertIntoAM_PM() {
  var date = new Date();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

export function tConvert(time) {
  return new Date("1970-01-01T" + time + "Z").toLocaleTimeString("en-US", {
    timeZone: "UTC",
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  });
}

export function onlyNumber(e) {
  var code = (e.which) ? e.which : e.keyCode;
  if (code > 31 && (code < 48 || code > 57)) {
    return e.preventDefault();
  }
}