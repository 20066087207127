import React, { useState, useEffect } from "react";
import { Container, Accordion, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Breadcrumb from '../../../component/breadcrumb'
import LoginUser from '../../../component/login-register/login'
import PatientDetails from '../../../component/login-register/patientDetails'
import FamilyMember from '../../../component/family-member/familyMember'
import SelectDateTime from '../../../component/select-date-time/selectDateTime'
import { testDetails } from "../../../actions/get-calls";
import NewUserRegister from "../../../component/login-register/register";
import VerifyRegister from "../../../component/login-register/VerifyRegister";
import { baseURL, calculateAge, currentTimeConvertIntoAM_PM, dateFormatIntoYYYYMMDD, payLaterLink } from "../../../constants/app-constants";
import { setSelectedMembersReducer } from "../../../redux/slice/selected-members";
import { setSelectedMemberIdReducer } from "../../../redux/slice/selected-member-id";
import { clearPackageDetailReducer } from "../../../redux/slice/package-detail";
import { applyCoupon, orderPackage, payLater, removeCoupon, removeWallet } from "../../../actions/post-call";
import { setBookingSourceReducer } from "../../../redux/slice/booking-source";
import { setVendorReducer } from "../../../redux/slice/vendor";

const TestBooking = () => {

	const dispatch = useDispatch();
	const vendorId = useSelector((state) => state.vendor.value);
	const selectedPincode = useSelector((state) => state.selectedPincode.value);
	const callToken = useSelector((state) => state.callToken.value);
	const switchToken = useSelector((state) => state.switchToken.value);

	const selectedUser = useSelector((state) => state.selectedUser.value);
	const _members = useSelector((state) => state.selectedMembers.value);
	const _memberId = useSelector((state) => state.selectedMemberId.value);
	const packageDetail = useSelector((state) => state.packageDetail.value);
	const bookingSource = useSelector((state) => state.bookingSource.value);

	const [registered, setRegistered] = useState(true);
	const [verifyOTP, setVerifyOTP] = useState(false);
	const [mobile, setMobile] = useState('');
	const [otpVerified, setOtpVerified] = useState(false);

	const [modal, setModal] = useState(false);

	const [currentDeliveryDetail, setCurrentDeliveryDetail] = useState(null);
	const [isDeliveryDetailAdded, setIsDeliveryDetailAdded] = useState(false);

	const [selectedDay, setSelectedDay] = useState(new Date());

	const [profileSelect, setProfileSelect] = useState(false);
	const [members, setMembers] = useState(null);

	const [bookingResponse, setBookingResponse] = useState(null);
	const [currentCityId, setCurrentCityId] = useState(null);
	const [isPackageSaved, setIsPackageSaved] = useState(false);
	const [isDateSelected, setIsDateSelected] = useState(false);

	const [packageTotalOrderAmount, setPackageTotalOrderAmount] = useState(0);
	const [packageDetailPayload, setPackageDetailPayload] = useState(null);
	const [orderDateTime, setOrderDateTime] = useState(null);

	const [isTermRead, setIsTermRead] = useState(false);
	const [payUsingWallet, setPayUsingWallet] = useState(false);
	const [couponCode, setCouponCode] = useState('');
	const [couponData, setCouponData] = useState(null);

	const [testVendor, setTestVendor] = useState(0);
	const [isVendorLoading, setIsVendorLoading] = useState(false);
	const [vendorList, setVendorList] = useState(null);
	const [currentVendor, setCurrentVendor] = useState(null);

	const [convenienceFee, setConvenienceFee] = useState(100);

	let JWTresponse = getJWTtoken();
	const checkingToken = checkToken();

	useEffect(() => {
		let currentBookingSource = window.location.pathname.split('/').pop();
		dispatch(setBookingSourceReducer(currentBookingSource));
		if (bookingSource) {
			if (bookingSource !== currentBookingSource) {
				dispatch(setSelectedMembersReducer(null));
				dispatch(setSelectedMemberIdReducer(null));
			}
		}
	}, [bookingSource])

	useEffect(async () => {
		if (!selectedPincode) {
			return showError("Pincode is missing, please go back and book for tests available for your pincode!");
		}

		if (packageDetail) {
			setIsVendorLoading(true);
			let testDetail = [];
			packageDetail.map((item) => { return testDetail.push(item.id); });

			const sendPayload = `pincode=${selectedPincode}&` +
				`vendor_id=2&` +
				`type=1&` +
				testDetail.map(function (el) { return 'test_id[]=' + el; }).join('&');

			let _vendorList = await testDetails(sendPayload);

			if (_vendorList) {
				if (_vendorList.data.data.partners) {
					let tempVendorList = [];

					for (const key in _vendorList.data.data.partners) {
						let testList = [];
						const vElement = _vendorList.data.data.partners[key];

						if (vElement.available_test_count === testDetail.length) {
							vElement.tests.map((tElement) => { return testList.push(tElement); });
							vElement._tests = testList;
							tempVendorList.push(vElement);
						}
					}

					setVendorList(tempVendorList);
				}
			}

			setIsVendorLoading(false);
		}

		cancelBooking();
		setBookingResponse(null);
		setIsPackageSaved(true);
	}, [_memberId])

	useEffect(() => {
		if (profileSelect) toggleAccordion(2);
	}, [profileSelect]);

	useEffect(() => {
		if (isDeliveryDetailAdded) toggleAccordion(3);
	}, [isDeliveryDetailAdded]);

	function handleShow() {
		setModal(true);
	}

	function handleClose() {
		setModal(false);
	}

	function getJWTtoken() {
		if (typeof window !== "undefined") {
			if (switchToken) {
				return switchToken;
			} else {
				return callToken;
			}
		} else if (callToken === undefined) {
			return null;
		} else {
			return null;
		}
	}

	function checkToken() {
		if (JWTresponse !== null) {
			return true;
		} else {
			return false;
		}
	}

	const resetBooking = () => {
		setProfileSelect(false);
	};

	useEffect(async () => {
		if (!isDeliveryDetailAdded) {
			cancelBooking();
		}
	}, [currentCityId, selectedDay, isDeliveryDetailAdded]);

	function addMorePackage(id) {
		dispatch(setSelectedMemberIdReducer(id));
		dispatch(clearPackageDetailReducer());
	}

	function clearAllPackageFromAllMember() {
		setIsPackageSaved(false);
		dispatch(setSelectedMembersReducer(null));
		setMembers(null);
		toggleAccordion(1);
	}

	function deletePackage(mainIndex, testId) {
		setIsPackageSaved(true);
		const removeOption = _members.map((o, index) => {
			return (index === mainIndex) ? { ...o, packages: o.packages.filter((s) => s.test_id !== testId) } : { ...o };
		});

		if (removeOption[mainIndex].packages.length === 0) removeOption.splice(mainIndex, 1);

		dispatch(setSelectedMembersReducer(removeOption));
		setMembers(removeOption);
	}

	function bookPackage() {
		if (!selectedUser) {
			return showError('Please login to continue!');
		}

		if (!currentDeliveryDetail) {
			return showError('Please fill delivery details!');
		}

		if (!packageDetailPayload) {
			return showError('Please select package!');
		}

		const currentTime = currentTimeConvertIntoAM_PM();
		setOrderDateTime(dateFormatIntoYYYYMMDD(selectedDay) + ' ' + currentTime);

		let payload = {
			patient_id: selectedUser.id,
			from_time: "",
			locality_name: "",
			order_time: currentTime,
			phlebo_charges: "",
			type: 2,
			address1: currentDeliveryDetail.address1,
			amount_after_discount: packageTotalOrderAmount.toString(),
			is_payment_completed: false,
			pin_code: currentDeliveryDetail.pincode,
			discount_amount: 0,
			to_time: "",
			unit_price: packageTotalOrderAmount.toString(),
			discount_percentage: 0,
			state_name: currentDeliveryDetail.state,
			slot_date: dateFormatIntoYYYYMMDD(selectedDay),
			city_name: currentDeliveryDetail.city,
			address2: currentDeliveryDetail.address2,
			booking_source: 3,
			order_date: orderDateTime,
			vendor_id: testVendor,
			slot_id: "",
			login_id: selectedUser.id.toString(),
			city_id: currentCityId ? currentCityId.toString() : "0",
			test_details: packageDetailPayload
		}

		orderPackage(payload)
			.then(function (response) {
				let res = response.data;

				if (res && res.errors) {
					res.errors.map((e) => {
						return showError(e.message);
					})
					return false;
				}

				setBookingResponse(res);
			})
			.catch(function (err) { });
	}

	function showError(str) {
		toast.error(str, {
			position: "top-right",
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: false,
			draggable: false
		});
	}

	function toggleAccordion(index) {
		let parentEl = document.getElementsByClassName('accordion-item')[index];
		let child = (parentEl.firstElementChild || parentEl.firstChild);
		let secondChild = (child.firstElementChild || child.firstChild);
		secondChild.click();
	}

	function formatOrderDateTime() {
		return moment(new Date(orderDateTime)).format("ddd, D MMM YYYY | hh:mm A");
	}

	function getJWTtoken() {
		if (switchToken) {
			return switchToken;
		} else if (callToken) {
			return callToken;
		} else {
			return null;
		}
	}

	function cancelBooking() {
		setBookingResponse(null);
		setCouponData(null);

		setCouponCode('');

		setIsTermRead(false);
		setPayUsingWallet(false);
		setIsDateSelected(false);
	}

	function applyCouponCode() {
		let JWTresponse = getJWTtoken();
		applyCoupon({
			booking_id: bookingResponse ? bookingResponse.order_id : null,
			coupon_code: couponCode,
			is_diagnostics: 1,
			type: 1
		}, JWTresponse)
			.then(function (response) {
				let res = response.data;
				if (res && res.errors && res.errors.length > 0) {
					return showError(res.errors[0].message);
				}

				setCouponData(res);
			})
			.catch(function (err) { });
	}

	function removeCouponCode() {
		let JWTresponse = getJWTtoken();

		removeCoupon({
			booking_id: bookingResponse ? bookingResponse.order_id : null
		}, JWTresponse)
			.then(function (response) {
				let res = response.data;
				if (res && res.errors && res.errors.length > 0) {
					return showError(res.errors[0].message);
				}

				setCouponData(null);
			})
			.catch(function (err) { });
	}

	function manageWallet(value) {
		let JWTresponse = getJWTtoken();

		setPayUsingWallet(value);
		if (value) { return false; }

		removeWallet({
			booking_id: bookingResponse ? bookingResponse.order_id : null
		}, JWTresponse)
			.then(function (response) {
				let res = response.data;
				if (res && res.errors && res.errors.length > 0) {
					return showError(res.errors[0].message);
				}
			})
			.catch(function (err) { });
	}

	function payLaterForNow(value) {
		let JWTresponse = getJWTtoken();

		if (value) { return false; }

		payLater({
			booking_id: bookingResponse ? bookingResponse.id : null,
			vendor_id: vendorId
		}, JWTresponse)
			.then(function (response) {
				let res = response.data;
				if (res && res.errors && res.errors.length > 0) {
					return showError(res.errors[0].message);
				}
				window.open(payLaterLink + (bookingResponse ? bookingResponse.id : ''), '_blank').focus();
				setModal(false);
				cancelBooking();
			})
			.catch(function (err) { });
	}

	function setVendor(id, _currentVendor) {
		if (id) {
			setCurrentVendor(_currentVendor);
			setTestVendor(id);
			dispatch(setVendorReducer(id));

			setTimeout(() => {
				let parentEl = document.getElementsByClassName('accordion-item')[1];
				let child = (parentEl.firstElementChild || parentEl.firstChild);
				let secondChild = (child.firstElementChild || child.firstChild);
				secondChild.click();
			}, 300);
		}
	}

	useEffect(() => {
		if (_memberId && currentVendor) {
			let tempMember = [], newTestList = [];
			newTestList = [...currentVendor._tests]

			for (let index = 0; index < _members.length; index++) {
				let mElement = _members[index];

				if (mElement.id === _memberId) {
					if (mElement.packages.length === 0) {
						let finalPackage;
						mElement = { ...mElement, ...finalPackage };
						newTestList.forEach(pElement => {
							let finalPackage;
							finalPackage = { packages: [...currentVendor._tests] };
							mElement = { ...mElement, ...finalPackage };
						});
					} else {
						newTestList.forEach(pElement => {
							if (mElement.packages.findIndex(p => p.test_id === pElement.test_id) < 0) {
								let finalPackage, oldPackage = mElement.packages;
								oldPackage = [...oldPackage, { ...pElement }];
								finalPackage = { packages: [...oldPackage] };
								mElement = { ...mElement, ...finalPackage };
							}
						});
					}
				}
				tempMember.push(mElement);

				if (mElement.packages.length > 0) {
					let _price = 0, memberPackages = [];
					setPackageTotalOrderAmount(0);

					mElement.packages.forEach(pElement => {
						let _mPackage = {
							name: pElement.test_name,
							product_name: pElement.diagnostic_name,
							test_code: pElement.test_code,
							unit_price: pElement.test_price,
							net_amount: pElement.test_price,
							price_type: "",
							test_id: pElement.test_id,
							diagnostic_id: pElement.diagnostic_id
						};

						_price = _price + parseInt(pElement.test_price);
						memberPackages.push(_mPackage);
					});

					setPackageTotalOrderAmount(_price);

					let finalTempMember = [];
					finalTempMember.push({
						patient_id: mElement.id,
						type: 2,
						patient_name: mElement.first_name + ' ' + mElement.last_name,
						tests: [...memberPackages]
					});

					setPackageDetailPayload(finalTempMember);
				}
			}

			setBookingResponse(null);
			setMembers(tempMember);
			dispatch(setSelectedMembersReducer(tempMember));
		}
	}, [currentVendor, _memberId])

	return (
		<React.Fragment>
			<Container>
				<Breadcrumb url="" chlidrenUrl="" firstText="Book Test" text="Booking" />
				<div className="row">
					<div className="col-lg-6">
						<Accordion defaultActiveKey="0" className="mhc-profile-tabs">
							<Accordion.Item eventKey="0">
								<Accordion.Header>1. Select Vendor</Accordion.Header>
								<Accordion.Body className="py-3">
									{
										!isVendorLoading ?
											vendorList ? vendorList.map((vendorItem) => {
												return (
													<div className="form-check mhc-vender-checkbox mb-2" key={vendorItem.vendor_id}>
														<input
															className="form-check-input"
															type="radio"
															name="vendorId"
															value={vendorItem.id}
															onChange={() => { setVendor(vendorItem.vendor_id, vendorItem) }}
														/>
														<label className="form-check-label w-100" htmlFor="">
															<div className="d-flex align-items-center justify-content-between w-100 ms-4">
																<div className="imgholder">
																	<img src={vendorItem.vendor_image} className="brand-name" alt={vendorItem.vendor_name} />
																</div>
																<p className="m-0 text-primary rates">INR {vendorItem.test_price_total}</p>
															</div>
														</label>
													</div>
												)
											}) : <div className="mx-2 mt-3 alert alert-danger text-center">No tests available for this location!</div>
											: <div className="mhc-Loding text-center my-2">
												<img src="../loader.gif" alt="loader" />
											</div>
									}
								</Accordion.Body>
							</Accordion.Item>

							{
								currentVendor ?
									<>
										<Accordion.Item eventKey="1">
											<Accordion.Header>2. Login/ Register</Accordion.Header>
											<Accordion.Body className="p-3 px-0">

												{(registered && !checkingToken) &&
													<LoginUser
														registeredState={setRegistered}
														setMobile={setMobile}
														setOtpVerified={setOtpVerified} />
												}

												{(!registered && !verifyOTP) && (
													<NewUserRegister
														setVerifyOTP={setVerifyOTP}
														mobile={mobile} />
												)}

												{verifyOTP && (
													<VerifyRegister
														mobile={mobile}
														setOtpVerified={setOtpVerified}
													/>
												)}

												{(otpVerified || checkingToken) && (
													<FamilyMember
														resetBooking={resetBooking}
														setProfileSelect={setProfileSelect}
														setIsPackageSaved={setIsPackageSaved}
														mode="checkbox"
													/>
												)}
											</Accordion.Body>
										</Accordion.Item>

										{
											profileSelect ? (
												<>
													<Accordion.Item eventKey="2">
														<Accordion.Header>3. Patient Details &amp; Address</Accordion.Header>
														<Accordion.Body className="py-3">
															<PatientDetails
																setCurrentDeliveryDetail={setCurrentDeliveryDetail}
																setIsDeliveryDetailAdded={setIsDeliveryDetailAdded}
																setCurrentCityId={setCurrentCityId}
															/>
														</Accordion.Body>
													</Accordion.Item>

													{
														isDeliveryDetailAdded ? (
															<Accordion.Item eventKey="3">
																<Accordion.Header>4. Pick your date</Accordion.Header>
																<Accordion.Body className="py-3">
																	<SelectDateTime
																		selectedDay={selectedDay}
																		setSelectedDay={setSelectedDay}
																		minDate={moment().toDate()}
																	/>

																	<div className="text-end mt-4">
																		<button type="button" className="btn btn-outline-secondary rounded-1 me-2" onClick={() => toggleAccordion(1)}>Add More Member</button>
																		<button disabled={selectedDay ? false : true} className="btn btn-outline-primary rounded-1" onClick={() => bookPackage()}>Continue</button>
																	</div>
																</Accordion.Body>
															</Accordion.Item>
														) : ''
													}
												</>
											) : ''
										}
									</> : ''
							}
						</Accordion>
					</div>

					{
						members ? (
							<div className="col-lg-6">
								{
									members ? members.map((memberItem, mIndex) => {
										return (
											<div className="mhc-package-details mb-2" key={memberItem.id}>
												<div className={`row align-items-center${memberItem.packages.length !== 0 ? ' border-bottom' : ''}`}>
													<div className="col-4">Patient Name </div>
													<div className="col-auto">
														<span >:</span>
													</div>
													<div className="col-5"><span className="fw-bold">{memberItem.first_name} {calculateAge(memberItem.dob)} Yrs, {memberItem.gender === "Male" ? "M" : "F"}, {(selectedUser && (selectedUser.id === memberItem.id)) ? 'Self' : memberItem.relation}</span></div>
													<div className="col text-end">
														<Link
															to="/book-package-or-test/test"
															onClick={(e) => addMorePackage(memberItem.id)}
															className="btn btn-sm btn-outline-secondary rounded-1">Add Test</Link>
													</div>
												</div>

												{
													memberItem.packages ? memberItem.packages.map((packageItem, index) => {
														return (
															<div className="row mt-2" key={index}>
																<div className="col-9"><span>{packageItem.test_name}</span></div>
																<div className="col-3 text-end">
																	<span className="me-2">:</span>
																	<span >INR {packageItem.test_price ? packageItem.test_price : 0}</span>
																	<i className="mhc-icon icon-trash-empty cursor-pointer" onClick={() => deletePackage(mIndex, packageItem.test_id)}></i>
																</div>
															</div>
														)
													}) : ''
												}
											</div>
										)
									}) : ''
								}

								{
									bookingResponse ? (
										<>
											{
												(selectedUser && selectedUser.mobile_number) ?
													<div className="mhc-patient-details mt-4">
														<div className="row mt-2">
															<div className="col-5">Mobile No. </div>
															<div className="col-auto"><span >:</span></div>
															<div className="col-6"><span className="fw-bold">+{selectedUser.isd_code} {selectedUser.mobile_number}</span></div>
														</div>
														<div className="row mt-2">
															<div className="col-5">Address</div>
															<div className="col-auto"><span>:</span></div>
															<div className="col-6"><span className="fw-bold">{currentDeliveryDetail.address1}, {currentDeliveryDetail.address2}, {currentDeliveryDetail.city}, {currentDeliveryDetail.state}, {currentDeliveryDetail.pincode}</span></div>
														</div>
														<div className="row mt-2">
															<div className="col-5">Appointment</div>
															<div className="col-auto"><span >:</span></div>
															<div className="col-6"><span className="fw-bold text-primary">{formatOrderDateTime()}</span></div>
														</div>
													</div> : ''
											}

											<div className="mhc-payment-details mt-4">
												<div className="row mt-2">
													{
														!couponData ? (
															<>
																<div className="col-md-9 col-lg-9 col-xl-10">
																	<div className="form-floating">
																		<input
																			type="text"
																			className="form-control"
																			id="CouponCode"
																			placeholder="Coupon Code"
																			value={couponCode}
																			onChange={(e) => setCouponCode(e.target.value)}
																		/>
																		<label htmlFor="name">Coupon Code </label>
																	</div>
																</div>
																<div className="col-md-3 col-lg-3 col-xl-2 mt-2 mt-md-0">
																	<button className="btn btn-outline-secondary w-100 rounded-1 bg-white" onClick={(e) => applyCouponCode()}>
																		Apply
																	</button>
																</div>
															</>
														) : (
															<div className="col-12">
																<label className="alert alert-success w-100">Coupon code has been applied successfully.
																	<a className="btn btn-link p-0 text-danger ms-1 text-decoration-none" onClick={(e) => removeCouponCode()}>Remove coupon</a>
																</label>
															</div>
														)
													}

													<div className="row mt-2">
														<div className="col-6">Package Fee </div>
														<div className="col-6 text-end"><span className="me-1">:</span><span className="fw-bold">INR {bookingResponse && bookingResponse.final_amount} </span></div>
													</div>

													<div className="row mt-2">
														<div className="col-6">Convenience Fee </div>
														<div className="col-6 text-end"><span className="me-1">:</span><span className="fw-bold">INR {convenienceFee}</span></div>
													</div>
													{
														couponData && (
															<div className="row mt-2">
																<div className="col-6">Coupon Discount </div>
																<div className="col-6 text-end">
																	<span className="me-1">:</span>
																	<span className="fw-bold">INR {couponData.discount_amount}</span>
																</div>
															</div>
														)
													}
													<div className="row mt-2">
														<div className="col-6">
															<div className="form-check">
																<input
																	className="form-check-input"
																	type="checkbox"
																	id="MyWallet"
																	checked={payUsingWallet}
																	onChange={(e) => manageWallet(e.target.checked)}
																/>
																<label
																	className="form-check-label d-block d-sm-inline"
																	htmlFor="MyWallet">
																	Use MyWallet for Payment
																</label>
															</div>
														</div>
														<div className="col-6 text-end">
															<span className="me-1">:</span>
															<span className="fw-bold">INR {bookingResponse && bookingResponse.wallet_amount}</span>
														</div>
													</div>

													<div className="border-bottom border-light w-100 transition mt-3"></div>

													<div className="row mt-2">
														<div className="col-6"><span className="fw-bold">Total to be paid</span></div>
														<div className="col-6 text-end">
															<span className="fw-bold ">
																INR {
																	payUsingWallet ? (bookingResponse &&
																		(
																			(parseInt(bookingResponse.final_amount) > bookingResponse.wallet_amount) ?
																				(
																					bookingResponse.final_amount && (
																						couponData ?
																							(couponData.total_amount + convenienceFee) :
																							(parseInt(bookingResponse.final_amount) + convenienceFee)
																					)
																				) : 0
																		)
																	) : (bookingResponse && bookingResponse.final_amount) && (
																		couponData ?
																			(couponData.total_amount + convenienceFee) :
																			(parseInt(bookingResponse.final_amount) + convenienceFee)
																	)
																}
															</span>
														</div>
													</div>
												</div>
											</div>

											<div className="mhc-register-form mt-4">
												<div className="form-check">
													<input
														className="form-check-input"
														type="checkbox"
														onClick={(e) => setIsTermRead(e.target.checked)}
														id="isTerm"
													/>
													<label className="form-check-label  d-block d-sm-inline" htmlFor="isTerm">
														I have read and agree to the <a href="" className="text-primary text-decoration-none">Terms and Conditions.</a>
													</label>
												</div>
											</div>

											{
												bookingResponse ? (
													<div className="row my-4">
														<div className="col-12 text-center text-md-end">
															{
																isTermRead ?
																	(
																		<>
																			<button disabled className="btn btn-outline-secondary rounded-1">Cancel</button>
																			<button className="btn btn-outline-primary ms-3 rounded-1" onClick={handleShow}>Pay Later</button>
																			<a target="_blank" href={(bookingResponse && bookingResponse.action) ? (baseURL + bookingResponse.action.uri) : ''} className="btn btn-primary ms-3 rounded-1">Pay Now</a>
																		</>
																	) : (
																		<>
																			<button onClick={(e) => {
																				cancelBooking();
																				resetBooking();
																				clearAllPackageFromAllMember();
																			}} className="btn btn-outline-secondary rounded-1">Cancel</button>
																			<button disabled className="btn btn-outline-primary ms-3 rounded-1">Pay Later</button>
																			<button disabled className="btn btn-primary ms-3 rounded-1">Pay Now</button>
																		</>
																	)
															}
														</div>
													</div>
												) : ''
											}
										</>
									) : ''
								}
							</div>
						) : ''
					}

				</div>
			</Container>

			<Modal show={modal} onHide={handleClose} size="md" >
				<Modal.Body >
					<p className="fs-6 text-center fw-bold">Using “Pay Now” option is more rewarding
						As you can earn/redeem wallet points.</p>
					<div className="row mt-4">
						<div className="col-12 text-center ">
							<button type="button" onClick={() => payLaterForNow()} className="btn btn-outline-primary rounded-1" >Pay Later</button>
							<a target="_blank" rel="noreferrer" href={(bookingResponse && bookingResponse.action) ? (baseURL + bookingResponse.action.uri) : ''} className="btn btn-primary ms-3 rounded-1" onClick={handleClose}>Pay Now</a>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<ToastContainer />
		</React.Fragment >
	)
}

export default TestBooking;